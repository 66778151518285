import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Box } from "@chakra-ui/react";
import Moment from "moment-timezone";
import { formatUnitForSensorType } from "../utils/sensorUnitUtils";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const TemperatureLineChart = ({ data, selectedGraph, selectedType, deviceData, overview }) => {
    const distinctReadings = new Set();
    const firstTenData = [];

    if (data) {
        for (let point of data) {
            if (!distinctReadings.has(point.fetch_time)) {
                distinctReadings.add(point.fetch_time);
                firstTenData.push(point);
            }
        }
    }

    firstTenData.sort((a, b) => new Date(a.fetch_time) - new Date(b.fetch_time));

    // custom labels (show full date for the first or midnight points)
    // const labels = firstTenData.map((point, index) => {
    //     const fetchTime = Moment(point.fetch_time);
    //     if (index === 0 || fetchTime.hour() === 0) {
    //         return fetchTime.format("DD-MM-YYYY");
    //     }
    //     return fetchTime.format("hh:mm A");
    // });
    const labels = firstTenData.map((point, index) => {
        const fetchTime = Moment(point.fetch_time);
        if (overview === "DAILY") {
            return fetchTime.format("DD-MM-YYYY");
        }
        if (index === 0) {
            return fetchTime.format("DD-MM-YYYY");
        }
        return fetchTime.format("hh:mm A");
    });
    // const Max = deviceData?.[selectedGraph === "sensor1" ? "sensor_one" : "sensor_two"]?.max_value;
    // const Min = deviceData?.[selectedGraph === "sensor1" ? "sensor_one" : "sensor_two"]?.min_value;

    const chartData = {
        // labels: firstTenData.map((point) => new Date(point.fetch_time).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false })),
        // labels: firstTenData.map((point) => Moment(point.fetch_time).format("DD-MM-YYYY HH:mm A")),
        labels,
        datasets: [
            {
                label: selectedType === "humidity" ? "Humidity" : "Temperature",
                data: firstTenData.map((point) =>
                    selectedGraph === "sensor1" ? Number(point.sensor_one).toFixed(1) : Number(point.sensor_two).toFixed(1),
                ),

                // backgroundColor: selectedType === "humidity" ? "rgba(153, 233, 233, 0.5)" : "rgba(255, 204, 0, 0.5)",
                borderColor: selectedType === "humidity" ? "#99E9E9" : "#FFCC00",
                borderWidth: 3,
                tension: 0.4,
                pointRadius: 0,
            },
            // {
            //     label: "Max Value",
            //     data: new Array(firstTenData?.length).fill(Max),
            //     borderDash: [10, 5], // Dashed line for upper range

            //     borderWidth: 2,

            //     pointRadius: 0,
            //     borderColor: "#FF5733",
            //     type: "line",
            // },
            // {
            //     label: "Min Value",
            //     data: new Array(firstTenData?.length).fill(Min),
            //     borderDash: [10, 5], // Dashed line for lower range
            //     pointRadius: 0,
            //     borderWidth: 2,
            //     borderColor: "#3CB371",
            //     type: "line",
            // },
        ],
    };
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Time",
                },
                grid: {
                    display: false,
                },
                ticks: {
                    callback: function (value, index, values) {
                        const label = chartData.labels[index];
                        console.log(label);

                        if (!label) return "";

                        return label;
                    },
                    font: {
                        size: function (context) {
                            const label = chartData.labels[context.index];

                            const isFullDate = label.includes("-");
                            const isMidnight = label === "12:00 AM";
                            const isNewHour = !isFullDate && label.endsWith(":00");

                            if (isFullDate || isMidnight || isNewHour) {
                                return 16;
                            }

                            return 14;
                        },
                        weight: function (context) {
                            const label = chartData.labels[context.index];

                            const isFullDate = label.includes("-");
                            const isMidnight = label === "12:00 AM";
                            const isNewHour = !isFullDate && label.endsWith(":00");

                            if ((isFullDate || isMidnight || isNewHour) && overview !== "DAILY") {
                                return "bold";
                            }

                            return "normal";
                        },
                    },
                },
            },
            y: {
                title: {
                    display: true,
                    text: selectedType === "humidity" ? "Humidity" : "Temperature",
                },
                ticks: {
                    stepSize: 1,
                },
            },
        },
        plugins: {
            legend: false,
            tooltip: {
                enabled: true,
                mode: "index",
                intersect: false,
                callbacks: {
                    // Customize the tooltip title to show the formatted date and time
                    title: () => "",
                    label: (tooltipItem) => {
                        // Customize the label for each data point
                        const formattedValue = formatUnitForSensorType({
                            sensorType: selectedType === "humidity" ? "HumidityHumidity" : "HumidityTemperature",
                            value: tooltipItem.raw,
                        });
                        return `${tooltipItem.dataset.label}: ${formattedValue}`;
                    },
                    footer: (tooltipItems) => {
                        // Always use the original fetch_time to format full date and time
                        const rawFetchTime = firstTenData[tooltipItems[0].dataIndex].fetch_time;
                        const fetchMoment = Moment(rawFetchTime);
                        const date = fetchMoment.format("DD-MM-YYYY");
                        const time = fetchMoment.format("hh:mm A");
                        return ["Recorded at : ", `Date: ${date}`, `Time: ${time}`];
                    },
                },
            },
        },
    };
    return (
        <Box width="100%" height="100%">
            <Line data={chartData} options={options} style={{ width: "100%", height: "100%" }} />
        </Box>
    );
};

export default TemperatureLineChart;
