import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormLabel,
    Input,
    Text,
    HStack,
    Select,
    InputGroup,
    InputRightElement,
    FormErrorMessage,
    FormControl,
    Image,
    Grid,
    GridItem,
    Tooltip,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CustomButton from "./CustomButton";
import plusIcon from "../assets/Plus_Icon.png";
import Step2EditDevice from "./Step2EditDevice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useQueryParams } from "use-query-params";
import { DEVICE_QUERY_PARAMS } from "../lib/queriesParams";
import { SEARCH_PARAMS } from "../constants";
import temp from "../../src/assets/temsvg.svg";
import humidity from "../../src/assets/humidity.png";
import { Switch } from "@chakra-ui/react";
import disable_info from "../../src/assets/disable_info_icon.svg";
import info from "../../src/assets/info_Icon.svg";
import { css } from "@emotion/react";

const HumidityHumidity = undefined;

const inputStyle = {
    borderRadius: "4px",
    border: "1px solid #81868180",
    fontFamily: "fontFamily",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: "-0.01em",
    color: "#000000",
    _focus: {
        border: "1.5px solid #57BA47",
        boxShadow: "none",
    },
    "::placeholder": {
        color: "#818681",
        opacity: 0.4,
    },
};

const labelStyle = {
    fontFamily: "fontFamily",
    fontWeight: "800",
    fontSize: "16px",
    lineHeight: "30px",
    letterSpacing: "-0.01em",
    marginBottom: "4px",
    color: "#11250E",
};

const InputField = {
    border: "1.5px solid rgba(129, 134, 129, 1)",
    _placeholder: {
        fontFamily: "fontFamily",
        color: "rgba(129, 134, 129, 1)",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "20px",
        letterSpacing: "-0.01em",
        alignContent: "start",
    },
    _focus: {
        border: "1.5px solid rgba(129, 134, 129, 1)",
        borderColor: "none",
        boxShadow: "none",
    },
    _hover: {
        border: "1.5px solid rgba(129, 134, 129, 1)",
    },
};

const labelField = {
    color: "rgba(17, 37, 14, 1)",
    fontFamily: "fontFamily",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "30px",
    letterSpacing: "-0.1%",
};

const Heading = {
    color: "rgba(17, 37, 14, 1)",
    fontFamily: "fontFamily",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "30px",
    letterSpacing: "-0.01em",
};

const noteStyle = {
    color: "rgba(129, 134, 129, 1)",
    fontFamily: "fontFamily",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    letterSpacing: "-0.14%",
};

const fetchDevicesBySpace = async (space) => {
    const response = await axios.get(`/device`, { params: { space_id: space } });
    return response.data;
};

const EditDevice = () => {
    const queryClient = useQueryClient();
    const { space, room } = useParams();
    const [query, setQuery] = useQueryParams(DEVICE_QUERY_PARAMS);
    const isOpen = query[SEARCH_PARAMS.DEVICE.EDIT.key] !== "";
    const [inputWidth, setInputWidth] = useState("auto");

    const { data: rooms, ...roomsQuery } = useQuery({
        queryKey: ["fetchRooms", space],
        enabled: false,
    });

    const { data: prevDevice } = useQuery({
        queryKey: ["fetchDevices", space],
        queryFn: () => fetchDevicesBySpace(space),
        enabled: !!space,
        select: (data) => {
            return data.data?.find((item) => item.id === query[SEARCH_PARAMS.DEVICE.EDIT.key]);
        },
    });

    const clearQuery = () => {
        setQuery({
            [SEARCH_PARAMS.DEVICE.EDIT.key]: SEARCH_PARAMS.DEVICE.EDIT.default_value,
            [SEARCH_PARAMS.DEVICE.EDIT_STATUS.key]: SEARCH_PARAMS.DEVICE.EDIT_STATUS.default_value,
        });
    };

    const validation = useFormik({
        initialValues: {
            name: prevDevice?.name || "",
            sensor_one_min_value: prevDevice?.sensor_one?.min_value || prevDevice?.sensor_one?.min_limit || "",
            sensor_one_max_value: prevDevice?.sensor_one?.max_value || prevDevice?.sensor_one?.max_limit || "",
            sensor_two_min_value: prevDevice?.sensor_two?.min_value || prevDevice?.sensor_two?.min_limit || "",
            sensor_two_max_value: prevDevice?.sensor_two?.max_value || prevDevice?.sensor_two?.max_limit || "",
            room: prevDevice?.room || room || "",
            sensor_one_enabled: prevDevice?.sensor_one_enabled || false,
            sensor_two_enabled: prevDevice?.sensor_two_enabled || false,
            sensor_one_threshold: prevDevice?.sensor_one?.in_range_threshold || 0,
            sensor_two_threshold: prevDevice?.sensor_two?.in_range_threshold || 0,
        },
        validationSchema: Yup.object({
            name: Yup.string().min(3, "Name is too short.").required("Name is required."),
            sensor_one_min_value: Yup.number().required("Min value is required."),
            sensor_one_max_value: Yup.number().required("Max value is required."),
            sensor_two_min_value: Yup.number().required("Min value is required."),
            sensor_two_max_value: Yup.number().required("Max value is required."),
            room: Yup.string().required("Room selection is required."),
        }),
        onSubmit: (values) => {
            updateDeviceMutation.mutate(values);
        },
        enableReinitialize: true,
    });

    const updateDeviceMutation = useMutation({
        mutationFn: async (values) => {
            const response = await axios.patch(
                `/device`,
                {
                    name: values.name,
                    room: values.room,
                    sensor_one_data: {
                        min_value: values.sensor_one_min_value,
                        max_value: values.sensor_one_max_value,
                        in_range_threshold: values.sensor_one_threshold,
                    },
                    sensor_two_data: {
                        min_value: values.sensor_two_min_value,
                        max_value: values.sensor_two_max_value,
                        in_range_threshold: values.sensor_two_threshold,
                    },
                    sensor_one_enabled: values.sensor_one_enabled,
                    sensor_two_enabled: values.sensor_two_enabled,
                },
                {
                    params: { device_id: prevDevice.id },
                },
            );
            return response;
        },
        onSuccess: (response) => {
            validation.resetForm();
            queryClient.refetchQueries({
                queryKey: ["fetchDevices", space],
                exact: true,
            });
            setQuery({ [SEARCH_PARAMS.DEVICE.EDIT_STATUS.key]: "success" });
        },
        onError: (error) => {
            console.error(error?.response?.data || error);
        },
    });

    const handleToggle = (field) => () => {
        validation.setFieldValue(field, !validation.values[field]);
    };
    const customSwitchStyle = css`
        .chakra-switch__track {
            background-color: rgba(224, 224, 224, 1);
        }
        .chakra-switch__track[data-checked] {
            background-color: rgba(19, 134, 66, 1);
        }
        .chakra-switch__thumb {
            background-color: white;
        }
    `;
    function CustomSwitch({ id, isChecked, onChange }) {
        return <Switch id={id} isChecked={isChecked} onChange={onChange} css={customSwitchStyle} />;
    }

    return (
        <>
            <Drawer placement="right" isOpen={isOpen} onClose={clearQuery}>
                <DrawerOverlay />
                <DrawerContent minWidth={{ base: 380, md: 480, lg: 500 }} my="5px" marginRight="1rem" borderRadius="10px">
                    <Box mt={{ base: "0px", sm: "0px", md: "5px", lg: "10px" }} mr="10px">
                        <DrawerCloseButton sx={{ color: "#11250E", p: "8px", bg: "#F6F6F6" }} />
                        <DrawerHeader
                            fontFamily="fontFamily"
                            fontSize="28px"
                            fontWeight="800"
                            lineHeight="42px"
                            letterSpacing="-0.04em"
                            textAlign="left"
                            color="#11250E"
                        >
                            Manage Device
                        </DrawerHeader>
                    </Box>

                    <DrawerBody>
                        <Box mb={4} p={5} bg="#F8F8F8">
                            <Text fontFamily="fontFamily" align="left" color="#818681" letterSpacing="-0.01em" fontSize="14px" fontWeight="800">
                                You are setting up this device in{" "}
                                <Box as="span" color="#11250E">
                                    {prevDevice?.roomDevice?.name}.
                                </Box>
                            </Text>
                            <Text align="left" color="#818681" fontSize="14px" fontWeight="500">
                                The device will monitor {(prevDevice?.sensor_one?.sensor_type === HumidityHumidity && "Humidity") || "Temperature"}{" "}
                                and {(prevDevice?.sensor_two?.sensor_type === HumidityHumidity && "Humidity") || "Temperature"} inside the room.
                            </Text>
                        </Box>
                        <Box mb={4}>
                            <FormControl isInvalid={validation.errors.room && validation.touched.room} isRequired>
                                <FormLabel htmlFor="room" sx={labelStyle}>
                                    Select Room
                                </FormLabel>
                                <Select
                                    id="room"
                                    placeholder="Select room"
                                    sx={inputStyle}
                                    value={validation.values.room}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                >
                                    {rooms?.data?.length > 0 &&
                                        rooms.data.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                </Select>
                                <FormErrorMessage>{validation.errors.room}</FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box mb={4}>
                            <FormControl isInvalid={validation.errors.name && validation.touched.name} isRequired>
                                <FormLabel htmlFor="name" sx={labelStyle}>
                                    Device Name
                                </FormLabel>
                                <Input
                                    id="name"
                                    placeholder="ex: Device 01"
                                    type="text"
                                    sx={inputStyle}
                                    value={validation.values.name}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                                <FormErrorMessage>{validation.errors.name}</FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box mb={5}>
                            <GridItem w="100%" h="5vh">
                                <Text sx={labelStyle}>Configure Sensor</Text>
                            </GridItem>

                            <GridItem mb="0.5rem" w="100%">
                                <Text sx={noteStyle}>Set minimum and maximum thresholds limits and receive alerts based on your preferences</Text>
                            </GridItem>
                        </Box>
                        {["sensor_one", "sensor_two"].map((sensor, index) => (
                            <Box w="100%" mb="1.5rem" key={sensor}>
                                <Flex w="100%" justifyContent="space-between">
                                    <Flex minW="90%" gap={2} align="center">
                                        <Image
                                            src={prevDevice?.[sensor]?.sensor_type === HumidityHumidity ? humidity : temp}
                                            alt=""
                                            w=" 20px"
                                            h="20px"
                                        />
                                        <Text
                                            color="rgba(17, 37, 14, 1)"
                                            fontFamily="fontFamily"
                                            fontSize="16px"
                                            lineHeight="30px"
                                            letterSpacing="-0.01%"
                                            fontWeight={600}
                                        >
                                            {(sensor === "sensor_one" && "Sensor One ") || (sensor === "sensor_two" && "Sensor Two ")}{" "}
                                            {prevDevice?.[sensor]?.sensor_type === HumidityHumidity ? "Humidity Range" : "Temperature Range"}
                                        </Text>
                                    </Flex>
                                    <FormControl display="flex" justifyContent="end" align="center">
                                        {/* <Switch
                                            id={`${sensor}_enabled`}
                                            colorScheme="teal"
                                            isChecked={validation.values[`${sensor}_enabled`]}
                                            onChange={handleToggle(`${sensor}_enabled`)}
                                        /> */}
                                        <CustomSwitch
                                            id={`${sensor}_enabled`}
                                            isChecked={validation.values[`${sensor}_enabled`]}
                                            onChange={handleToggle(`${sensor}_enabled`)}
                                        />
                                    </FormControl>
                                </Flex>

                                <Grid gridTemplateColumns="repeat(3,1fr)" w="100%" gap={3}>
                                    <GridItem width="120px">
                                        <FormControl isRequired isDisabled={!validation.values[`${sensor}_enabled`]}>
                                            <FormLabel mb="0.5rem" sx={labelField} isDisabled={!validation.values[`${sensor}_enabled`]}>
                                                Set Min Limit
                                            </FormLabel>
                                            <Box display="flex" alignItems="center" width={inputWidth} position="relative">
                                                <Input
                                                    id={`${sensor}_min_value`}
                                                    minW="17px"
                                                    border="none"
                                                    placeholder="00"
                                                    type="text"
                                                    value={validation.values[`${sensor}_min_value`]}
                                                    onChange={validation.handleChange}
                                                    onFocus={validation.handleBlur}
                                                    sx={InputField}
                                                    isDisabled={!validation.values[`${sensor}_enabled`]}
                                                />
                                                <Box
                                                    borderLeft="1px solid rgba(155, 151, 151, 1)"
                                                    w="22px"
                                                    h="25px"
                                                    display="flex"
                                                    alignItems="center"
                                                    zIndex={1}
                                                    position="absolute"
                                                    left="70%"
                                                    top="5px"
                                                >
                                                    <Text ml="3px">{prevDevice?.[sensor]?.sensor_type === HumidityHumidity ? "%" : "°C"}</Text>
                                                </Box>
                                            </Box>
                                        </FormControl>
                                    </GridItem>
                                    {/* Repeat for other GridItems */}
                                    <GridItem width="120px">
                                        <FormControl isRequired isDisabled={!validation.values[`${sensor}_enabled`]}>
                                            <FormLabel mb="0.5rem" sx={labelField} isDisabled={!validation.values[`${sensor}_enabled`]}>
                                                Set Max Limit
                                            </FormLabel>
                                            <Box display="flex" alignItems="center" width={inputWidth} position="relative">
                                                <Input
                                                    id={`${sensor}_max_value`}
                                                    minW="17px"
                                                    placeholder="00"
                                                    value={validation.values[`${sensor}_max_value`]}
                                                    onChange={validation.handleChange}
                                                    onFocus={validation.handleBlur}
                                                    sx={InputField}
                                                    isDisabled={!validation.values[`${sensor}_enabled`]}
                                                />
                                                <Box
                                                    borderLeft="1px solid rgba(155, 151, 151, 1)"
                                                    w="22px"
                                                    h="25px"
                                                    display="flex"
                                                    alignItems="center"
                                                    zIndex={1}
                                                    position="absolute"
                                                    left="76%"
                                                    top="5px"
                                                >
                                                    <Text ml="3px"> {prevDevice?.[sensor]?.sensor_type === HumidityHumidity ? "%" : "°C"}</Text>
                                                </Box>
                                            </Box>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem width="180px">
                                        <FormControl isRequired isDisabled={!validation.values[`${sensor}_enabled`]}>
                                            <Flex align="center">
                                                <FormLabel mb="0.5rem" sx={labelField} isDisabled={!validation.values[`${sensor}_enabled`]}>
                                                    Out-Range Threshold
                                                </FormLabel>
                                                <Tooltip
                                                    placement="auto"
                                                    hasArrow
                                                    bg="gray.500"
                                                    label="Out-Range Threshold refers to the limit beyond which a sensor's reading is considered irregular or abnormal"
                                                >
                                                    {!validation.values.sensor_two_enabled ? (
                                                        <Image src={disable_info} alt="" mb="0.5rem" />
                                                    ) : (
                                                        <Image src={info} alt="" mb="0.5rem" />
                                                    )}
                                                </Tooltip>
                                            </Flex>

                                            <Box display="flex" alignItems="center" width={inputWidth} position="relative">
                                                <Input
                                                    minW="17px"
                                                    border="none"
                                                    placeholder="00"
                                                    name={`${sensor}_threshold`}
                                                    type="number"
                                                    value={validation.values[`${sensor}_threshold`]}
                                                    isInvalid={validation.errors[`${sensor}_threshold`] && validation.touched[`${sensor}_threshold`]}
                                                    onFocus={validation.handleBlur}
                                                    onChange={validation.handleChange}
                                                    sx={InputField}
                                                    isDisabled={!validation.values[`${sensor}_enabled`]}
                                                />
                                                <Box
                                                    borderLeft="1px solid rgba(155, 151, 151, 1)"
                                                    w="22px"
                                                    h="25px"
                                                    display="flex"
                                                    alignItems="center"
                                                    zIndex={1}
                                                    position="absolute"
                                                    left="80%"
                                                    top="5px"
                                                >
                                                    <Text ml="3px">Min</Text>
                                                </Box>
                                            </Box>
                                            {/* <FormErrorMessage>{validation.errors.paringKey}</FormErrorMessage> */}
                                        </FormControl>
                                    </GridItem>
                                </Grid>
                            </Box>
                        ))}
                    </DrawerBody>

                    <DrawerFooter>
                        <Flex direction="column" justifyContent="center" gap={2} width="100%">
                            <CustomButton
                                width="100%"
                                h="40px"
                                bg="#57BA47"
                                sx={{
                                    _hover: { bg: "#57BA47" },
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                }}
                                onClick={validation.handleSubmit}
                                disabled={
                                    !validation.isValid ||
                                    updateDeviceMutation.isLoading ||
                                    (!validation.values.sensor_one_enabled && !validation.values.sensor_two_enabled)
                                }
                            >
                                Done
                            </CustomButton>
                        </Flex>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
            <Step2EditDevice />
        </>
    );
};

export default EditDevice;
